<template>
    <div class="common-layout">
        <el-container>
            <el-header height="50px">
                <div class="flex_row_between_center">
                    <div class="flex_row_start_center">
                        <div class="flex_row_center_center img_logo_part">
                            <div>
                                <img class="img_logo"
                                    :src="adminInfo.data.background_logo ? imgUrl + adminInfo.data.background_logo : imLogo"
                                    alt @click='goHome' />
                                <div class="lineState" @click="changeLine">
                                    <div class="yuan_on" v-if="onlineState == 1"></div>
                                    <div class="yuan_busy" v-if="onlineState == 2"></div>
                                    <div class="yuan_off" v-if="onlineState == 3"></div>
                                    <div style="margin-left: 10px;">{{ lineState }}</div>
                                    <div class="line_box" v-if="popFlag" @mouseleave="popFlag = false">
                                        <!-- <div class="pop_header">
                                            <p><i class="el-icon-close" @click="popFlag = false"></i></p>
                                        </div> -->
                                        <div class="triangle-left"></div>

                                        <div class="pop_list_l">
                                            <div class="pop_item" v-for="(item, index) in quickReplyList" :key="index"
                                                @click="sendQuick(item.value)">
                                                <div class="yuan_on" v-if="item.value == 1"></div>
                                                <div class="yuan_busy" v-if="item.value == 2"></div>
                                                <div class="yuan_off" v-if="item.value == 3"></div>
                                                <div style="margin-left: 5px;">{{ item.title }}</div>
                                            </div>
                                        </div>

                                    </div>
                                </div>

                            </div>

                        </div>

                        <span class="header_title">{{ L['平台-客服助手'] }}</span>
                    </div>
                    <div class="header_right flex_row_end_center">
                        <div @click="pageClose" class="flex_row_start_center" style="cursor: pointer;">
                            <div class="back_img">
                                <img src="@/assets/back.png" alt="">
                            </div>
                            <span class="back_text">返回管理后台</span>
                        </div>

                        <div class="right_tab">
                            <div class="right_tab_vi flex_row_center_center">
                                <span class="store_name">{{ decodeURIComponent(adminName) }}</span>
                                <span class="el-icon-caret-bottom"></span>
                            </div>
                            <div class="right_tab_hi flex_row_center_center" @click="loginOut">
                                <span class="el-icon-switch-button"></span>
                                <span class="tab_name">退出登录</span>
                            </div>
                        </div>
                    </div>
                </div>
            </el-header>
            <el-container>
                <el-aside width="150px">
                    <router-link :to="{ path: '/service' }" class="sld_left_nav_item">
                        <i class="el-icon-postcard icon"></i>
                        <span class="text">{{ L['对话平台'] }}</span>
                    </router-link>

                    <router-link :to="{ path: '/history' }" class="sld_left_nav_item">
                        <i class="el-icon-chat-line-square icon"></i>
                        <span class="text">{{ L['聊天记录'] }}</span>
                    </router-link>

                    <router-link :to="{ path: '/autoReply' }" class="sld_left_nav_item">
                        <i class="el-icon-chat-dot-round icon"></i>
                        <span class="text">{{ L['欢迎语'] }}&nbsp;&nbsp;&nbsp;&nbsp;</span>
                    </router-link>
                    <router-link :to="{ path: '/quickReply' }" class="sld_left_nav_item">
                        <i class="el-icon-chat-line-round icon"></i>
                        <span class="text">{{ L['快捷回复'] }}</span>
                    </router-link>
                    <router-link :to="{ path: '/casualQues' }" class="sld_left_nav_item">
                        <i class="el-icon-collection icon"></i>
                        <span class="text">{{ L['常见问题'] }}</span>
                    </router-link>
                    <router-link :to="{ path: '/setting' }" class="sld_left_nav_item">
                        <i class="el-icon-set-up icon"></i>
                        <span class="text">{{ L['聊天设置'] }}</span>
                    </router-link>

                </el-aside>
                <el-container :style="{ maxWidth: `${clientWidth - 150}px`, minWidth: `${clientWidth - 150}px` }"
                    id="elContainer">
                    <el-main style="margin:10px">
                        <router-view @getCurMemberId="getCurMemberId" :adminInfo="adminInfo.data"></router-view>
                    </el-main>
                </el-container>
            </el-container>
        </el-container>
    </div>
</template>

<script>
import { ref, reactive, getCurrentInstance, onMounted, onUnmounted, provide, h } from "vue";
import '@/style/base.scss'
import { useRouter } from "vue-router";
import { useStore } from "vuex";
import { sourceUrl, imageUrl } from '@/utils/config'
import axios from 'axios'
import canAutoPlay from 'can-autoplay';
import { ElMessageBox, ElPopover, ElButton } from 'element-plus'
export default {
    name: 'Home',
    components: {},
    beforeCreate() {
        this.sockets.subscribe('contact_change', (e) => {
            if (this.curMemberInfo.memberId != e.memberId || e.memberId == -1) {
                document.title = '有一条新消息'
                if ((localStorage.msgVoiceSetting === true || localStorage.msgVoiceSetting === 'true')) {
                    this.play();
                }
            } else {
                document.title = '平台-客服助手'
            }
        });
    },

    created() {
        this.sockets.subscribe('connect', (e) => {
            console.log('连接了吗', e)

            this.$socket.emit("connect_success", this.connectBaseData, (i) => {
                console.log('连接了吗', i)
                if (this.isDisConnect) {
                    this.$socket.emit("vendor_change_room", { memberId: this.curMemberInfo.memberId, ...this.connectBaseData }, () => { });
                    this.isDisConnect = false
                }

                this.getlineState()

            });

        });

        this.sockets.subscribe('disconnect', (e) => {
            console.log('离线了吗', e)
            this.isDisConnect = true
        });

    },

    setup() {
        const store = useStore();
        const { proxy } = getCurrentInstance();
        const isCollapse = ref(false);
        const imLogo = require("@/assets/im_logo.png");
        const router = useRouter();
        const storeInfo = reactive({ data: {} });//店铺基本信息
        const clientWidth = ref(0)
        const connectBaseData = { userId: localStorage.adminId, role: 2 };
        const adminInfo = reactive({ data: {} })
        const isDisConnect = ref(false)
        const adminName = localStorage.adminName
        const L = proxy.$getCurLanguage()
        const popFlag = ref(false)
        const lineState = ref('在线')
        const onlineState = ref(1)
        const curMemberInfo = ref({})
        const quickReplyList = ref([
            { title: '在线', value: 1 },
            { title: '忙碌', value: 2 },
            { title: '离线', value: 3 },
        ])

        provide('adminInfo', adminInfo.data)
        // const imgUrl = ref('https://image.qingfeifeicui.com');
        const imgUrl = ref(imageUrl)
        //退出登录功能
        const loginOut = () => {
            //调用商户的退出登录接口
            let sld_refresh_token = localStorage.getItem('sld_refresh_token');
            let param = new FormData();
            // param.append('grant_type', 'refresh_token');
            param.append('refresh_token', sld_refresh_token);
            axios.post(sourceUrl + "v3/adminLogin/admin/oauth/logout", param).then(res => {
                console.log(res)
            }, err => {
                console.log(err)
            })
            //清除全部缓存
            localStorage.clear();
            window.location.replace(sourceUrl + 'user/login?flag=exit');

        }
        const goHome = () => {
            router.push({
                path: "/service",
            });
        }
        provide('sharedValue', lineState)
        //获取在线,忙碌，离线状态
        const getlineState = () => {
            proxy.$get('v3/system/admin/adminUser/detail').then(res => {
                lineState.value = res.data.onlineStateValue
                onlineState.value = res.data.onlineState
            })
        }
        // 改变在线离线状态
        const sendQuick = (item) => {
            console.log(11, item)
            proxy.$get('v3/system/admin/adminUser/updateOnlineState', {
                onlineState: item
            }).then(res => {
                if (res.state == 200) {
                    popFlag.value = false

                    window.location.reload()

                    if (item == 1) {
                        proxy.$socket.emit("connect_success");
                        getlineState()


                    } else {
                        proxy.$socket.disconnect()

                    }
                }
            })
        }

        //获取声音的设置
        const getVoiceSetting = () => {
            proxy.$post('v3/helpdesk/admin/sound/setting/isOpen').then(res => {
                store.commit("updateMsgVoiceSetting", res.data.isOpen ? true : false);
            })
        }
        const changeLine = () => {
            popFlag.value = true
            console.log('popFlag--', popFlag.value)
        }


        const getAdminSetting = () => {
            proxy.$get('v3/system/admin/setting/getSettingList', {
                str: 'platform_customer_service_logo,customer_service_background_logo'
            }).then(res => {
                if (res.state == 200) {
                    adminInfo.data.admin_logo = res.data[0].imageUrl
                    localStorage.setItem('admin_logo', adminInfo.data.admin_logo)
                }

                if (res.data[1].imageUrl) {
                    adminInfo.data.background_logo = res.data[1].imageUrl
                    localStorage.setItem('background_logo', res.data[1].imageUrl)
                }
            })
        }


        const pageClose = () => {
            if (navigator.userAgent.indexOf("MSIE") > 0) {
                if (navigator.userAgent.indexOf("MSIE 6.0") > 0) {
                    window.opener = null;
                    window.close();
                } else {
                    window.open('', '_top');
                    window.top.close();
                }
            }
            else if (navigator.userAgent.indexOf("Firefox") > 0) {
                window.location.href = 'about:blank ';
            } else {
                window.opener = null;
                window.open('', '_self', '');
                window.close();
            }
        }

        const getCurMemberId = (memberInfo) => {
            curMemberInfo.value = memberInfo
        }


        // 消息提示音
        const play = () => {
            console.log('playHome')
            let audioElement = document.createElement('audio');
            let voice = require('@/assets/voice/msg.mp3')
            audioElement.setAttribute('src', voice);
            audioElement.setAttribute('autoplay', 'autoplay');
        }

        router.beforeEach((to, from, next) => {
            // to and from are both route objects. must call `next`.
            if (from.name == 'service') {
                proxy.$socket.emit("vendor_change_room", {
                    oldMemberId: curMemberInfo.value.oldMemberId,
                    memberId: -1,
                    ...connectBaseData
                })
            }
            next()
        })


        const detectAutoPlay = () => {
            canAutoPlay.audio().then(res => {
                if (!res.result) {
                    ElMessageBox({
                        title: '提示',
                        message: h('div', null, [
                            h('p', null, '您的浏览器没有开启自动播放声音的功能，点击确认后可接收消息提示音。'),
                            h('p', null, '也可以点击浏览器地址前面的图标->网站设置->声音，选择允许，下次可以自动接收消息提示音。')
                        ]),
                        confirmButtonText: '确认',
                    }).then(() => {

                    })
                }
            })
        }

        onMounted(() => {
            getAdminSetting()
            clientWidth.value = document.body.offsetWidth || document.documentElement.offsetWidth
            window.onbeforeunload = () => {
                proxy.$socket.disconnect()
            }
            //检测是否可以播放声音
            detectAutoPlay()
            proxy.$socket.emit("connect_success");
            getVoiceSetting();
            getlineState()


        })

        onUnmounted(() => {
            proxy.$socket.disconnect()
        })
        return { imgUrl, isCollapse, popFlag, getlineState, sendQuick, lineState, onlineState, quickReplyList, changeLine, isDisConnect, connectBaseData, imLogo, goHome, loginOut, storeInfo, clientWidth, L, adminName, pageClose, curMemberInfo, play, getCurMemberId, adminInfo }
    }
}
</script>
<style lang="scss" scoped>
.sld_left_nav_item {
    width: 100%;
    display: block;
    color: #fff;
    height: 50px;
    line-height: 50px;
    background: #1D2647;

    .icon {
        color: #fff;
        font-size: 16px;
        margin-right: 10px;
        font-weight: bold;
    }

    .text {
        color: #fff;
        font-size: 14px;
    }

    &.router-link-active {
        background: #fff;

        .icon {
            color: #1D2647;
        }

        .text {
            color: #1D2647;
        }
    }
}

.el-header,
.el-footer {
    background-color: #fff;
}

.el-header {
    padding-left: 0 !important;
    padding-right: 10px;

    .img_logo_part {
        width: 150px;
        height: 50px;
        background: #1D2647;
        padding-top: 37px;
        z-index: 1;

        .img_logo {
            width: 37px;
            cursor: pointer;
        }
    }

    .lineState {
        display: flex;
        color: white;
        background: #1D2647;
        height: 26px;
        margin-top: 10px;
        text-align: center
    }

    .yuan_busy {
        width: 10px;
        height: 10px;
        background-color: red;
        border-radius: 50%;
    }

    .yuan_on {
        width: 10px;
        height: 10px;
        background-color: green;
        border-radius: 50%;
    }

    .yuan_off {
        width: 10px;
        height: 10px;
        background-color: silver;
        border-radius: 50%;
    }

    .header_title {
        display: inline-block;
        height: 50px;
        font-size: 20px;
        font-family: Microsoft YaHei;
        font-weight: bold;
        color: #114A97;
        line-height: 50px;
        margin-left: 20px;
    }

    .header_right {
        .login_out {
            cursor: pointer;

            &:hover {
                color: #1c94fa;
            }
        }
    }
}

.el-aside {
    color: #fff;
    text-align: center;
    background-color: #1D2647;
    padding-top: 36px;
}

.el-main {
    background-color: #fff;
    height: calc(100vh - 70px);
    padding: 15px;
}

.el-container {
    background: #f0f2f5;
}

.el-menu {
    height: calc(100vh - 50px);
    background-color: #1D2647;
}

.el-menu-item,
.el-menu-item i {
    color: #fff;
}

.el-menu-item:hover {
    background-color: #fff;

    .el-menu-item,
    .el-menu-item i {
        color: #1D2647;
    }
}

.el-menu-item.is-active {
    color: #1D2647;
}

.el-container:nth-child(5) .el-aside,
.el-container:nth-child(6) .el-aside {
    line-height: 260px;
}

.el-container:nth-child(7) .el-aside {
    line-height: 320px;
}

.el-menu-vertical-demo:not(.el-menu--collapse) {
    width: 150px;
    min-height: 400px;
}

.right_tab {
    position: relative;
    min-width: 132px;

    &:hover {
        .right_tab_hi {
            display: flex;
        }

        .right_tab_vi {
            background: #F5F5F5;

            .store_name,
            .el-icon-caret-bottom {
                color: #333 !important;

            }
        }
    }

    .right_tab_vi {

        height: 50px;
        background: #0E6FD7;
        cursor: pointer;
        padding: 0 10px;


        .store_name {
            font-size: 16px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            color: #FFFEFE;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
        }

        .el-icon-caret-bottom {
            font-size: 14px;
            color: #FFFEFE;
            margin-left: 9px;
        }
    }

    .right_tab_hi {
        height: 51px;
        background: #F5F5F5;
        position: absolute;
        top: 50px;
        width: 100%;
        left: 0;
        font-size: 14px;
        font-family: Source Han Sans CN;
        font-weight: 400;
        color: #333333;
        cursor: pointer;
        display: none;

        .tab_name {
            margin-left: 8px;
            margin-top: 2px;
        }

        .el-icon-switch-button {
            font-weight: bold;
            font-size: 16px;
        }
    }
}

.back_img {
    margin-right: 10px;
    cursor: pointer;

    img {
        width: 20px;
        height: 20px;
    }
}

.back_text {
    margin-right: 20px;
    font-size: 14px;
    font-family: Source Han Sans CN;
    font-weight: 400;
    color: #A2A7BB;
}

.triangle-left {
    position: absolute;
    left: -16px;
    top: 5px;
    width: 0;
    height: 0;
    border-left: 8px solid transparent;
    border-right: 8px solid white;
    border-top: 8px solid transparent;
    border-bottom: 8px solid transparent;
}

.line_box {
    position: absolute;
    top: 50px;
    background-color: #fff;
    padding-bottom: 10px;
    left: 108px;
}

.pop_list_l {
    margin-top: 5px;
    height: 100px;
    width: 100px;

    .pop_item {
        display: flex;
        // margin-left: 10px;
        padding: 10px 26px;
        border-bottom: 1px solid #e7e7e7;
        width: 97%;
        color: black;

        &:hover {
            background: #F8F8F8;
        }


    }
}
</style>
